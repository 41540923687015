import React from 'react';
// import { Link as GatsbyLink } from 'gatsby';
// import { Mixpanel } from '@/utils';
// import Button from '@/components/Button';
import LinkedInIcon from '@/components/icons/LinkedIn';
import TwitterIcon from '@/components/icons/Twitter';
import InstagramIcon from '@/components/icons/Instagram';
import FacebookIcon from '@/components/icons/Facebook';
import MediumIcon from '@/components/icons/Medium';
import Subscribe from '@/views/newsletter';
import UnsubscribePopup from '@/views/home/popup-modal/unsubscribe-popup';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import Link from './Link';

export default function Footer() {
  const [subscribed, setSubscribed] = useLocalStorage(
    `SubscribedToNewsletter`,
    `null`,
  );

  const isLandingPage =
    typeof window !== `undefined` && window.location.pathname === `/`;
  function scrollToHowItWorks(evt: any) {
    if (isLandingPage) {
      evt?.preventDefault();
      document.getElementById(`hiw`)?.scrollIntoView(true);
    }
  }

  // async function handleBlogClicked(evt: any) {
  //   evt?.preventDefault();
  //   await Mixpanel.track(`View Blog`);
  //   if (typeof window !== `undefined`) {
  //     window.open(`https://medium.com/@VitractOfficial`, `_blank`);
  //   }
  // }

  return (
    <footer>
      <div
        className="promotion-cta h-[64px] flex items-center justify-center px-4 md:px-0"
        data-aos="flip-down"
      >
        <p className="text-center text-[#484352]">
          We got into Techstars NYC startup accelerator program 🎉. Read more
          about it{` `}
          <a
            target="_blank"
            href="https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022"
            className="underline hover:text-[#FF713F]"
            rel="noreferrer"
          >
            here
          </a>
        </p>
      </div>
      <div className="bg-[#FBFBFB] w-full md:px-[60px] lg:px-auto px-[30px]">
        <div className="max-w-[1180px] pt-[42px] pb-[40px] grid grid-cols-12 gap-y-[54px] sm:gap-y-0 justify-between mx-auto">
          <div className="col-span-12 sm:col-span-4">
            <img
              src="/images/logo.svg"
              className="h-[30px]"
              alt="Vitract | Using personalized nutrition to treat depression"
            />
            <p className="mt-[30px] text-[16px] font-normal text-gray-light">
              838 Walker Rd, Dover, Delaware, <br />
              19904 United States
            </p>
          </div>
          <div className="col-span-12 sm:col-span-4">
            <h3 className="text-lg font-medium text-black">Explore Vitract</h3>
            <ul className="mt-[26px] grid gap-y-1">
              <li>
                <Link
                  href="/science"
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                >
                  Science
                </Link>
              </li>
              <li>
                <Link
                  href="/pricing"
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                >
                  Pricing
                </Link>
              </li>
              <li>
                {/* <Button
                  onClick={scrollToHowItWorks as any}
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                  text="How it works"
                /> */}
                <Link
                  href="/"
                  onClick={scrollToHowItWorks as any}
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                >
                  How it works
                </Link>
              </li>
              <li>
                {/* <button
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                  onClick={handleBlogClicked}
                  type="button"
                >
                  Blog
                </button> */}
                <Link
                  href="/blogs"
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                >
                  Research
                </Link>
              </li>
              <li>
                <Link
                  href="/privacy-policy"
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  href="/faq"
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  href="/customs"
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                >
                  Customs
                </Link>
              </li>
              <li>
                <Link
                  href="/downloads/Website-Terms-and-Conditions.pdf"
                  className="text-[16px] font-normal text-gray-light hover:text-[#FF713F]"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <UnsubscribePopup />
              </li>
            </ul>
          </div>
          <div className="col-span-12 sm:col-span-4">
            <h3 className="text-lg font-medium text-black">Connect with us</h3>
            <ul className="mt-[26px] inline-flex gap-x-8 items-center">
              <li>
                <Link
                  href="https://www.linkedin.com/company/vitract/about/"
                  target="_blank"
                  className="text-[16px] text-gray-darker opacity-100 hover:opacity-95 hover:text-primary font-medium"
                >
                  <LinkedInIcon className="text-current" />
                </Link>
              </li>
              <li>
                <Link
                  href="https://www.instagram.com/vitractofficial/"
                  target="_blank"
                  className="text-[16px] text-gray-darker opacity-100 hover:opacity-95 hover:text-primary font-medium"
                >
                  <InstagramIcon className="text-current" />
                </Link>
              </li>
              <li>
                <Link
                  href="https://twitter.com/VitractOfficial"
                  target="_blank"
                  className="text-[16px] text-gray-darker opacity-100 hover:opacity-95 hover:text-primary font-medium"
                >
                  <TwitterIcon className="text-current" />
                </Link>
              </li>
              <li>
                <Link
                  href="https://web.facebook.com/Vitract-111349774876378"
                  target="_blank"
                  className="text-[16px] text-gray-darker opacity-100 hover:opacity-95 hover:text-primary font-medium"
                >
                  <FacebookIcon className="text-current" />
                </Link>
              </li>
              <li>
                <Link
                  href="https://medium.com/@VitractOfficial"
                  target="_blank"
                  className="text-[16px] text-gray-darker opacity-100 hover:opacity-95 hover:text-primary font-medium"
                >
                  <MediumIcon className="text-current" />
                </Link>
              </li>
            </ul>

            <div className="max-w-[80%]">
              <h3 className="mt-10 text-lg font-medium text-black pb-[19px]">
                Subscribe to our newsletter
              </h3>
              <Subscribe
                center={false}
                form1
                subscribed={subscribed}
                setSubscribed={setSubscribed}
              />
            </div>
          </div>
          {/* <div className="col-span-12 sm:col-span-4">
              <h3 className="text-lg font-medium text-black">
                Get our newsletter
              </h3>

              <form
                className="grid mt-[26px]"
                onSubmit={(evt) => handleSignUp(evt, `waitlist`)}
              >
                <input
                  type="email"
                  name="Email"
                  className="rounded-[4px] bg-[#FCFCFD] border border-[#CFD0D7] focus:outline-none focus:ring focus:ring-primary text-[12px] placeholder:text-[14px] px-3 py-[11px] flex items-center"
                  placeholder="Your email address"
                />
                <Button
                  isSubmit
                  text="Join our waitlist"
                  className="text-[10px] w-fit text-white bg-primary px-[14.29px] py-[7.15px] rounded-[4px] mt-4"
                />
              </form>
            </div> */}

          <div className="col-span-12  pt-[70px] text-[16px] font-medium w-fit mx-auto">
            Copyright © 2023 Vitract
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  children: null,
  className: ``,
};
